import { BoxProps, chakra, ChakraProps } from "@chakra-ui/react"
import Image, { ImageProps } from "next/legacy/image"
import React from "react"

/** @see https://nextjs.org/docs/api-reference/next/image */
export const NextImage = chakra(Image, {
  shouldForwardProp: (prop) =>
    [
      "width",
      "height",
      "src",
      "alt",
      "layout",
      "loading",
      "loader",
      "sizes",
      "priority",
      "loader",
      "quality",
      "objectFit",
      "objectPosition",
      "unoptimized",
      "role",
      "placeholder",
      "blurDataURL",
    ].includes(prop),
})

/** NextImageをインライン要素として表示する時にテキストと位置が合わない問題に対するワークアラウンド
 *
 * @see https://github.com/vercel/next.js/issues/19768
 */
export const NextImageInlineWrapper = (
  props?: React.PropsWithChildren<BoxProps>
) => (
  <chakra.div
    display="inline-block"
    fontSize="0"
    verticalAlign="top"
    {...props}
  />
)

export type NextImageProps = ImageProps & ChakraProps
