/** アイコン
 *
 * https://www.figma.com/file/kht8Q0UYSd1QD05lCvUYWc/moneiro?node-id=3098%3A5000
 *
 * 追加手順
 *
 * 1. アウトライン化する: Figma上で要素を右クリックして、`Outline Stroke`を実行
 * 2. SVGをコピーする: Figma上で要素を右クリックして、`Copy/Paste as` -> `Copy as SVG`を実行
 * 3. https://jakearchibald.github.io/svgomg/ に貼り付ける
 * 4. SVGを調整
 *  - `Prefer viewBox to width/height` をチェック
 *  - `Precision`を調整して、影響がないギリギリまで下げてファイルサイズを小さくする
 * 5. コードにもってくる
 *  - <path />が1つだけでfillRuleなども無い場合は`d`の値を`createIcon`のオプション`d`に渡す
 *  - ↑そうでない場合は`path`に渡す(pathのみか、配列か、Fragmentで囲む)
 *  - 属性値をHTMLからJSXに変換 (e.g. `fill-rule` -> `fillRule`)
 *  - 1色の単純なアイコンは色指定は`currentColor`に変更
 *  - <svg />に渡したい属性は`defaultProps`で指定する
 */
import { createIcon } from "@chakra-ui/react"
import React from "react"

export const MinusRoundedIcon = createIcon({
  path: [
    <path
      key="1"
      d="M 20 0 C 14.698 0 9.607 2.109 5.858 5.858 C 2.109 9.607 0 14.698 0 20 C 0 25.302 2.109 30.393 5.858 34.142 C 9.607 37.891 14.698 40 20 40 C 25.302 40 30.393 37.891 34.142 34.142 C 37.891 30.393 40 25.302 40 20 C 40 14.698 37.891 9.607 34.142 5.858 C 30.393 2.109 25.302 0 20 0 Z"
      fill="#404040"
      strokeWidth="1"
    />,
    <path
      key="2"
      d="M 10 17 L 30 17 L 30 23 L 10 23 Z"
      fill="#ffffff"
      strokeWidth="1"
    />,
  ],
  viewBox: "0 0 40 40",
})

export const PlusRoundedIcon = createIcon({
  path: [
    <path
      key="1"
      d="M 20 0 C 14.698 0 9.607 2.109 5.858 5.858 C 2.109 9.607 0 14.698 0 20 C 0 25.302 2.109 30.393 5.858 34.142 C 9.607 37.891 14.698 40 20 40 C 25.302 40 30.393 37.891 34.142 34.142 C 37.891 30.393 40 25.302 40 20 C 40 14.698 37.891 9.607 34.142 5.858 C 30.393 2.109 25.302 0 20 0 Z"
      fill="#404040"
      strokeWidth="1"
    />,
    <path
      key="2"
      d="M 10 17 L 30 17 L 30 23 L 10 23 Z"
      fill="#ffffff"
      strokeWidth="1"
    />,
    <path
      key="3"
      d="M 17 10 L 23 10 L 23 30 L 17 30 Z"
      fill="#ffffff"
      strokeWidth="1"
    />,
  ],
  viewBox: "0 0 40 40",
})

export const PlusIcon = createIcon({
  path: [
    <path
      key="1"
      d="M0.12793 5.13623C0.12793 4.72202 0.463716 4.38623 0.87793 4.38623H8.37793C8.79214 4.38623 9.12793 4.72202 9.12793 5.13623V5.13623C9.12793 5.55044 8.79214 5.88623 8.37793 5.88623H0.87793C0.463716 5.88623 0.12793 5.55044 0.12793 5.13623V5.13623Z"
      fill="currentColor"
    />,
    <path
      key="2"
      d="M4.78906 9.63623C4.37485 9.63623 4.03906 9.30044 4.03906 8.88623L4.03906 1.38623C4.03906 0.972017 4.37485 0.63623 4.78906 0.63623V0.63623C5.20328 0.63623 5.53906 0.972017 5.53906 1.38623L5.53906 8.88623C5.53906 9.30044 5.20328 9.63623 4.78906 9.63623V9.63623Z"
      fill="currentColor"
    />,
  ],
  viewBox: "0 0 10 10",
})

export const FlowIcon = createIcon({
  path: [
    <path
      key="1"
      d="M 20 0 C 14.698 0 9.607 2.109 5.858 5.858 C 2.109 9.607 0 14.698 0 20 C 0 25.302 2.109 30.393 5.858 34.142 C 9.607 37.891 14.698 40 20 40 C 25.302 40 30.393 37.891 34.142 34.142 C 37.891 30.393 40 25.302 40 20 C 40 14.698 37.891 9.607 34.142 5.858 C 30.393 2.109 25.302 0 20 0 Z"
      fill="#fccb4b"
      strokeWidth="1"
    />,
    <path
      key="2"
      d="M 20 26.667 L 29.382 17.292 L 10.618 17.292 L 20 26.667 Z"
      fill="#ffffff"
      strokeWidth="1"
    />,
    <path
      key="3"
      d="M 20 0 C 14.698 0 9.607 2.109 5.858 5.858 C 2.109 9.607 0 14.698 0 20 C 0 25.302 2.109 30.393 5.858 34.142 C 9.607 37.891 14.698 40 20 40 C 25.302 40 30.393 37.891 34.142 34.142 C 37.891 30.393 40 25.302 40 20 C 40 14.698 37.891 9.607 34.142 5.858 C 30.393 2.109 25.302 0 20 0 Z"
      fill="#fccb4b"
      strokeWidth="1"
    />,
    <path
      key="4"
      d="M 18.884 27.259 C 19.28 27.64 19.478 27.831 19.706 27.901 C 19.897 27.96 20.103 27.96 20.294 27.901 C 20.522 27.831 20.72 27.64 21.116 27.259 L 30.112 18.603 C 31.021 17.729 31.475 17.291 31.506 16.913 C 31.531 16.6 31.407 16.293 31.172 16.084 C 30.888 15.833 30.257 15.833 28.995 15.833 L 11.005 15.833 C 9.743 15.833 9.112 15.833 8.828 16.084 C 8.593 16.293 8.469 16.6 8.494 16.913 C 8.525 17.291 8.979 17.729 9.888 18.603 L 18.884 27.259 Z"
      fill="#ffffff"
      strokeWidth="1"
    />,
  ],
  viewBox: "0 0 40 40",
})

export const HamburgerIcon = createIcon({
  d: "M3 19a1 1 0 001 1h16a1 1 0 100-2H4a1 1 0 00-1 1zm0-7a1 1 0 001 1h16a1 1 0 100-2H4a1 1 0 00-1 1zm1-8a1 1 0 000 2h16a1 1 0 100-2H4z",
  viewBox: "0 0 24 24",
})

export const HamburgerCloseIcon = createIcon({
  path: [
    <path
      key="1"
      d="M17.435 18.85L4.707 6.12a1 1 0 111.414-1.414L18.85 17.435a1 1 0 01-1.414 1.414z"
    />,
    <path
      key="2"
      d="M4.707 17.435L17.435 4.707a1 1 0 111.414 1.414L6.121 18.85a1 1 0 01-1.414-1.414z"
    />,
  ],
  viewBox: "0 0 24 24",
})

export const MoneiroCircleIcon = createIcon({
  path: (
    <path
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      d="M9 1a8 8 0 100 16A8 8 0 009 1z"
    />
  ),
  viewBox: "0 0 18 18",
})

export const MoneiroCircleRoundedIcon = createIcon({
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 22a9 9 0 1 0 0-18 9 9 0 0 0 0 18Zm0 4a13 13 0 1 0 0-26 13 13 0 0 0 0 26Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 26 26",
  defaultProps: {
    fill: "none",
  },
})

export const MoneiroTriangleRoundedIcon = createIcon({
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 22.3a1 1 0 0 0 1 1.4h25a1 1 0 0 0 1-1.4L15.3.4a1 1 0 0 0-1.8 0L1.1 22.3ZM14.6 7 7.1 19.8H22L14.5 7Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 29 24",
  defaultProps: {
    fill: "none",
  },
})

export const MoneiroCrossRoundedIcon = createIcon({
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.6.6a2 2 0 0 1 2.8 0l9.6 9.8L22.6.6a2 2 0 0 1 2.8 2.8l-9.6 9.9 9 9.3a2 2 0 0 1-2.8 2.8l-9-9.2-9 9.2a2 2 0 1 1-2.9-2.8l9.1-9.3L.6 3.4A2 2 0 0 1 .6.6Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 26 26",
  defaultProps: {
    fill: "none",
  },
})
export const MoneiroTriangleIcon = createIcon({
  path: (
    <path
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      d="M1.74841 16L9.99992 1.97231L18.2516 16H1.74841Z"
    />
  ),
  viewBox: "0 0 20 17",
})

export const MoneiroCrossIcon = createIcon({
  path: (
    <path
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      d="M1.707 1.293L16.74 16.326m-.447-15.033L1.26 16.326"
    />
  ),
  viewBox: "0 0 18 18",
})

export const CircleIcon = createIcon({
  path: (
    <path
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      d="M9 14A5 5 0 109 4a5 5 0 000 10z"
    />
  ),
  viewBox: "0 0 18 18",
})

export const TriangleIcon = createIcon({
  path: (
    <path
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      d="M9.9 4.55a1 1 0 00-1.8 0l-4 8A1 1 0 005 14h8a1 1 0 00.9-1.45l-4-8z"
    />
  ),
  viewBox: "0 0 18 18",
})

export const CrossIcon = createIcon({
  d: "M10.68 9.13l2.54-2.54a.75.75 0 000-1.12l-.56-.56a.75.75 0 00-1.12 0L9 7.45 6.44 4.9a.76.76 0 00-1.12 0l-.56.56a.75.75 0 000 1.12L7.3 9.13l-2.54 2.56a.75.75 0 000 1.12l.56.55c.3.34.81.34 1.12 0L9 10.84l2.54 2.54c.3.33.81.33 1.12 0l.55-.56a.75.75 0 000-1.12l-2.53-2.56z",
  viewBox: "0 0 18 18",
})

export const SimpleMinusIcon = createIcon({
  d: "M10.84 8.64H7.16v.9h3.68v-.9z",
  viewBox: "0 0 18 18",
})

export const ChevronRightIcon = createIcon({
  viewBox: "0 0 24 24",
  defaultProps: {
    fontSize: "1.5em",
    verticalAlign: "top",
  },
  path: [
    <path
      key="1"
      fill="currentColor"
      d="M14.879 12.929L8.515 6.565A1 1 0 119.929 5.15l6.364 6.364a1 1 0 01-1.414 1.414z"
    />,
    <path
      key="2"
      fill="currentColor"
      d="M16.293 12.929l-6.364 6.364a1 1 0 01-1.414-1.414l6.364-6.364a1 1 0 011.414 1.414z"
    />,
  ],
})

export const MenuDropdownIcon = createIcon({
  viewBox: "0 0 10 6",
  d: "M5.63945 5.73717L9.7421 1.53849C10.086 1.18657 10.086 0.615854 9.7421 0.263937C9.39823 -0.0879791 8.84057 -0.0879791 8.4967 0.263937L5 3.84248L1.5033 0.263937C1.15943 -0.0879795 0.601768 -0.0879795 0.2579 0.263937C-0.0859671 0.615853 -0.0859671 1.18657 0.2579 1.53849L4.36016 5.73677C4.53658 5.91732 4.76868 6.0046 4.99961 5.99981C5.23093 6.0046 5.46342 5.91732 5.63945 5.73717Z",
})

export const BookIcon = createIcon({
  viewBox: "0 0 25 16",
  path: (
    <>
      <defs>
        <clipPath id="a">
          <path fill="currentColor" d="M0 0h25v16H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#a)">
        <path
          d="M11.811 1.248C11.676.396 10.913 0 9.97 0H2.844v13.305h6.093c.194 0 .359.016.538.031.135 0 .285 0 .405.015.164.015.299.061.449.107.09.03.209.03.299.076.15.061.285.152.419.228.075.046.135.061.21.122.194.152.404.35.599.578V1.507c0-.106-.03-.167-.045-.259zM9.475 3.943a.761.761 0 01-.538.228H5.763a.764.764 0 01-.539-.228.818.818 0 01-.225-.548c0-.426.345-.777.764-.777h3.174c.419 0 .763.351.763.777a.753.753 0 01-.225.548zm-.538 3.288H5.763a.774.774 0 01-.764-.776c0-.426.345-.777.764-.777h3.174c.419 0 .763.351.763.777a.773.773 0 01-.763.776zm5.061 6.531c.135-.091.269-.183.419-.228.09-.031.195-.046.299-.076.15-.031.285-.076.449-.107.12-.015.27-.015.405-.015.179-.015.329-.031.539-.031h6.092V0h-7.125c-.943 0-1.692.396-1.827 1.248-.015.092-.059.153-.059.244v12.97c.209-.243.404-.426.598-.578.075-.061.15-.076.21-.122zm2.111-6.531a.774.774 0 01-.764-.776c0-.426.345-.777.764-.777h3.173c.419 0 .764.351.764.777a.774.774 0 01-.764.776h-3.173zm3.727-3.288a.764.764 0 01-.539.228h-3.173a.762.762 0 01-.539-.228.79.79 0 01-.225-.548c0-.426.344-.777.764-.777h3.173c.419 0 .764.351.764.777a.897.897 0 01-.225.548z"
          fill="currentColor"
        />
        <path
          d="M23.337 1.248v13.199h-7.275c-1.198 0-1.512.168-2.021.761l-.674.792h11.632V1.248h-1.662zM8.952 14.447h-7.23V1.248H0v14.737h11.692l-.674-.777c-.509-.593-.823-.761-2.066-.761z"
          fill="currentColor"
        />
      </g>
    </>
  ),
})

export const LinkIcon = createIcon({
  viewBox: "0 0 24 24",
  path: (
    <>
      <path
        d="M18.21 5.18a4.47 4.47 0 00-6.06 0L9.8 7.47a.82.82 0 000 1.17c.33.33.88.33 1.2 0l2.35-2.28a2.68 2.68 0 013.64 0 2.46 2.46 0 010 3.54l-2.34 2.28a.82.82 0 000 1.18.86.86 0 001.21 0l2.34-2.28a4.08 4.08 0 00.01-5.9zm-5.76 9.15l-2.34 2.28c-.97.95-2.67.95-3.64 0a2.44 2.44 0 010-3.54l2.34-2.28a.82.82 0 000-1.18.88.88 0 00-1.2 0L5.25 11.9a4.08 4.08 0 000 5.9A4.32 4.32 0 008.29 19c1.14 0 2.22-.43 3.03-1.22l2.34-2.28a.82.82 0 000-1.18.87.87 0 00-1.2 0z"
        fill="#222"
      />
      <path
        d="M14.28 8.99a.92.92 0 00-1.28 0l-4.12 4a.86.86 0 000 1.25c.36.35.93.35 1.28 0l4.12-4a.86.86 0 000-1.25z"
        fill="#222"
      />
    </>
  ),
})

export const PlayIcon = createIcon({
  viewBox: "0 0 55 55",
  defaultProps: {
    color: "white",
  },
  path: (
    <>
      <path d="M38.5 28.3L22 40.5V16l16.5 12.3z" fill="currentColor" />
      <path
        d="M27.5.5a27 27 0 100 54 27 27 0 000-54z"
        fill="none"
        stroke="currentColor"
      />
    </>
  ),
})

export const BuildingIcon = createIcon({
  viewBox: "0 0 15 18",
  d: "M4.28571 4.91406V3.58594C4.28571 3.3668 4.46652 3.1875 4.6875 3.1875H6.02679C6.24777 3.1875 6.42857 3.3668 6.42857 3.58594V4.91406C6.42857 5.1332 6.24777 5.3125 6.02679 5.3125H4.6875C4.46652 5.3125 4.28571 5.1332 4.28571 4.91406ZM8.97321 5.3125H10.3125C10.5335 5.3125 10.7143 5.1332 10.7143 4.91406V3.58594C10.7143 3.3668 10.5335 3.1875 10.3125 3.1875H8.97321C8.75223 3.1875 8.57143 3.3668 8.57143 3.58594V4.91406C8.57143 5.1332 8.75223 5.3125 8.97321 5.3125ZM4.6875 8.5H6.02679C6.24777 8.5 6.42857 8.3207 6.42857 8.10156V6.77344C6.42857 6.5543 6.24777 6.375 6.02679 6.375H4.6875C4.46652 6.375 4.28571 6.5543 4.28571 6.77344V8.10156C4.28571 8.3207 4.46652 8.5 4.6875 8.5ZM8.97321 8.5H10.3125C10.5335 8.5 10.7143 8.3207 10.7143 8.10156V6.77344C10.7143 6.5543 10.5335 6.375 10.3125 6.375H8.97321C8.75223 6.375 8.57143 6.5543 8.57143 6.77344V8.10156C8.57143 8.3207 8.75223 8.5 8.97321 8.5ZM6.42857 11.2891V9.96094C6.42857 9.7418 6.24777 9.5625 6.02679 9.5625H4.6875C4.46652 9.5625 4.28571 9.7418 4.28571 9.96094V11.2891C4.28571 11.5082 4.46652 11.6875 4.6875 11.6875H6.02679C6.24777 11.6875 6.42857 11.5082 6.42857 11.2891ZM8.97321 11.6875H10.3125C10.5335 11.6875 10.7143 11.5082 10.7143 11.2891V9.96094C10.7143 9.7418 10.5335 9.5625 10.3125 9.5625H8.97321C8.75223 9.5625 8.57143 9.7418 8.57143 9.96094V11.2891C8.57143 11.5082 8.75223 11.6875 8.97321 11.6875ZM15 15.8047V17H0V15.8047C0 15.5855 0.180804 15.4062 0.401786 15.4062H1.05469V0.796875C1.05469 0.355273 1.41295 0 1.85826 0H13.1417C13.5871 0 13.9453 0.355273 13.9453 0.796875V15.4062H14.5982C14.8192 15.4062 15 15.5855 15 15.8047ZM2.66183 15.373H6.42857V13.1484C6.42857 12.9293 6.60938 12.75 6.83036 12.75H8.16964C8.39062 12.75 8.57143 12.9293 8.57143 13.1484V15.373H12.3382V1.62695L2.67857 1.59375L2.66183 15.373Z",
})

export const LiveIcon = createIcon({
  viewBox: "0 0 17 17",
  defaultProps: {
    color: "white",
  },
  path: (
    <>
      <path
        d="M14.87 4.75H9.5l2.33-2.33-.5-.5L8.5 4.75 5.67 1.92l-.5.5L7.5 4.75H2.12c-.77 0-1.41.63-1.41 1.42v8.5c0 .78.64 1.41 1.41 1.41h12.75c.78 0 1.42-.63 1.42-1.41v-8.5c0-.79-.64-1.42-1.42-1.42zm0 9.92H2.12v-8.5h12.75v8.5zm-8.5-7.09v5.67l4.96-2.83-4.96-2.84z"
        fill="currentColor"
      />
    </>
  ),
})

export const ExpandMoreIcon = createIcon({
  viewBox: "0 0 24 24",
  defaultProps: {
    color: "black",
  },
  path: (
    <>
      <path
        d="M11.071 12.071l6.364-6.364a1 1 0 111.414 1.414l-6.364 6.364a1 1 0 11-1.414-1.414z"
        fill="currentColor"
      />
      <path
        d="M11.071 13.485L4.707 7.121a1 1 0 011.414-1.414l6.364 6.364a1 1 0 01-1.414 1.414zM11.071 17.89l6.364-6.365a1 1 0 011.414 1.414l-6.364 6.364a1 1 0 01-1.414-1.414z"
        fill="currentColor"
      />
      <path
        d="M11.071 19.303L4.707 12.94a1 1 0 111.414-1.414l6.364 6.364a1 1 0 01-1.414 1.414z"
        fill="currentColor"
      />
    </>
  ),
})
