import { ChevronRightIcon } from "@chakra-ui/icons"
import {
  Box,
  Center,
  chakra,
  CSSObject,
  LinkBox,
  LinkOverlay,
  SimpleGrid,
  Stack,
} from "@chakra-ui/react"
import imgIcSodan from "@~/assets/illusts/insta/ic-sodan.svg"
import img3FundsSeminar from "@~/assets/layout/header/md/3-funds-seminar.png"
import imgEduConsulting from "@~/assets/layout/header/md/edu-consulting.png"
import imgFutureConsulting from "@~/assets/layout/header/md/future-consulting.png"
import imgHandWithSP from "@~/assets/layout/header/md/hand-with-sp.png"
import imgIdecoConsulting from "@~/assets/layout/header/md/ideco-consulting.png"
import imgIdecoSeminar from "@~/assets/layout/header/md/ideco-seminar.png"
import imgIdeco2Seminar from "@~/assets/layout/header/md/ideco2-seminar.png"
import imgInsuranceConsulting from "@~/assets/layout/header/md/insurance-consulting.png"
import imgMortgageSeminar from "@~/assets/layout/header/md/mortgage-seminar.png"
import imgSolution50sSeminar from "@~/assets/layout/header/md/solution-50s-seminar.png"
import imgMutualFundsConsulting from "@~/assets/layout/header/md/mutual-funds-consulting.png"
import imgNisaBasic2Seminar from "@~/assets/layout/header/md/nisa-basic2-seminar.png"
import imgNisaBasic3Seminar from "@~/assets/layout/header/md/nisa-basic3-seminar.png"
import imgNisaConsulting from "@~/assets/layout/header/md/nisa-consulting.png"
import imgNisaGrowthSeminar from "@~/assets/layout/header/md/nisa-growth-seminar.png"
import imgNisaLevelupSeminar from "@~/assets/layout/header/md/nisa-levelup-seminar.png"
import imgNisaReviseSeminar from "@~/assets/layout/header/md/nisa-revise-seminar.png"
import imgSmartSeminar from "@~/assets/layout/header/md/smart-seminar.png"
import { NextImage } from "components/system"
import NextLink from "next/link"
import { useRouter } from "next/router"
import React, { useEffect, useState } from "react"
import { NavLink } from "../../system/NavLink"
import {
  EDU_CONSULTING_LAYOUT_LINK,
  FUTURE_CONSULTING_LAYOUT_LINK,
  IDECO2_SEMINAR_LAYOUT_LINK,
  IDECO_CONSULTING_LAYOUT_LINK,
  IDECO_SEMINAR_LAYOUT_LINK,
  INSURANCE_CONSULTING_LAYOUT_LINK,
  MORTGAGE_SEMINAR_LAYOUT_LINK,
  MUTUAL_FUNDS_CONSULTING_LAYOUT_LINK,
  NISA_BASIC2_SEMINAR_LAYOUT_LINK,
  NISA_BASIC3_SEMINAR_LAYOUT_LINK,
  NISA_CONSULTING_LAYOUT_LINK,
  NISA_GROWTH_SEMINAR_LAYOUT_LINK,
  NISA_LEVELUP_SEMINAR_LAYOUT_LINK,
  NISA_REVISE_SEMINAR_LAYOUT_LINK,
  SMART_SEMINAR_LAYOUT_LINK,
  THREE_FUNDS_SEMINAR_LAYOUT_LINK,
  SOLUTION_50S_LAYOUT_LINK,
} from "../links"
import { useHeader } from "./Header"
import { MediaSearchInput } from "./MediaSearchInput"

export type HeaderMdMenuProps = {
  links: ReturnType<typeof useHeader>
  isAffiliate: boolean
  displayMediaSearchInput: boolean
}

export const HeaderMdMenu = ({
  links,
  isAffiliate,
  displayMediaSearchInput,
}: HeaderMdMenuProps) => {
  return (
    <>
      {!isAffiliate && (
        <Center>
          <NavLink
            href={links.simulation.href}
            prefetch={false}
            _hover={{ textDecoration: "none" }}
          >
            <chakra.div
              pos="relative"
              _after={UnderlineCSS}
              _hover={{
                _after: {
                  transform: "scale(1, 1)",
                },
              }}
            >
              {links.simulation.title}
            </chakra.div>
          </NavLink>
        </Center>
      )}
      <HeaderMegaMenu
        kind="seminar"
        title="セミナーで学ぶ"
        links={links.seminar}
      />
      <HeaderMegaMenu
        kind="consulting"
        title="相談する"
        links={links.consulting}
      />
      <Center ml={6}>
        <NavLink
          href={links.mypage.href}
          prefetch={false}
          _hover={{ textDecoration: "none" }}
        >
          <Box
            fontWeight="bold"
            color="moneiro-color-darkblue"
            px={2}
            py="8px"
            borderWidth="1px"
            borderColor="moneiro-color-darkblue"
            borderRadius="5px"
            transition="all 0.3s"
            _hover={{
              bgColor: "moneiro-color-darkblue",
              color: "#fff",
            }}
          >
            {links.mypage.title}
          </Box>
        </NavLink>
      </Center>
      {displayMediaSearchInput && (
        <Center ml={6}>
          <MediaSearchInput w="222px" />
        </Center>
      )}
    </>
  )
}

const HeaderMegaMenu = ({
  kind,
  title,
  links,
}: {
  kind: "seminar" | "consulting"
  title: string
  links: HeaderMdMenuProps["links"]["seminar"]
}) => {
  const { isOpenable } = useHeaderMdMenu()
  return (
    <HeaderMegaMenuCSS isOpenable={isOpenable}>
      <Box className="header__mega-menu">
        <chakra.div className="header__mega-menu-title-wrap">
          <chakra.span className="header__mega-menu-title-hover-area left"></chakra.span>
          <chakra.div className="header__mega-menu-title">{title}</chakra.div>
          <chakra.span className="header__mega-menu-title-hover-area right"></chakra.span>
        </chakra.div>
        <Box className="header__mega-menu-content">
          <HeaderMegaMenuContent kind={kind} links={links} />
        </Box>
      </Box>
    </HeaderMegaMenuCSS>
  )
}

const HeaderMegaMenuCSS = ({
  children,
  ...props
}: React.PropsWithChildren<{ isOpenable: boolean }>) => {
  return (
    <Box
      sx={{
        ".header__mega-menu": {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          h: "full",
          ml: 6,
        },
        ".header__mega-menu-title": {
          pos: "relative",
        },
        ".header__mega-menu-title-wrap": {
          pos: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          h: "full",
          cursor: "pointer",
        },
        ".header__mega-menu-title::after": UnderlineCSS,
        ".header__mega-menu-title-hover-area": {
          display: "none",
          bgColor: "transparent",
          w: "135px",
          h: "full",
          pos: "absolute",
        },
        ".header__mega-menu-title-hover-area.left": {
          top: 0,
          right: "100%",
          clipPath:
            "polygon(75% 0%, 100% 0%, 100% 100%, 0% 100%, 0% 67%, 75% 58%)",
        },
        ".header__mega-menu-title-hover-area.right": {
          top: 0,
          left: "100%",
          clipPath: "polygon(0 0, 22% 0, 22% 59%, 100% 67%, 100% 100%, 0 100%)",
        },
        ".header__mega-menu:hover > .header__mega-menu-title-wrap .header__mega-menu-title-hover-area":
          {
            display: "inline-block",
          },
        ".header__mega-menu:hover > .header__mega-menu-title-wrap > .header__mega-menu-title::after":
          {
            transform: "scale(1, 1)",
          },
        ".header__mega-menu:hover > .header__mega-menu-content":
          props.isOpenable
            ? {
                visibility: "visible",
                opacity: 1,
              }
            : {},
        ".header__mega-menu-content": {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          w: "full",
          bgColor: "#fff",
          py: 16,
          pos: "absolute",
          top: "100%",
          left: 0,
          visibility: "hidden",
          opacity: 0,
          transition: "opacity 0.3s",
        },
      }}
    >
      {children}
    </Box>
  )
}

const UnderlineCSS: CSSObject = {
  content: `""`,
  w: "full",
  h: "2px",
  bgColor: "moneiro-color-main-orange",
  pos: "absolute",
  bottom: "-5px",
  left: 0,
  transform: "scale(0, 1)",
  transformOrigin: "center",
  transition: "transform 0.3s",
}

const HeaderMegaMenuContent = ({
  kind,
  links,
}: {
  kind: "seminar" | "consulting"
  links: HeaderMdMenuProps["links"]["seminar"]
}) => {
  const topLayoutLink = links[0]
  const otherLayoutLinks = links.filter((_, idx) => idx !== 0)
  const isSeminar = kind === "seminar"
  const leftIconImgSrc = isSeminar ? imgHandWithSP : imgIcSodan
  const imgWidth = isSeminar ? 222 : 270
  const imgHeight = isSeminar ? 101 : 102

  return (
    <Stack spacing={9}>
      <NavLink
        href={topLayoutLink.href}
        prefetch={false}
        display="flex"
        alignItems="center"
      >
        <NextImage src={leftIconImgSrc} width={40} height={40} alt="" />
        <chakra.div textStyle="16-bold" ml={3}>
          {topLayoutLink.title}
        </chakra.div>
        <ChevronRightIcon boxSize="24px" ml={3} />
      </NavLink>
      <SimpleGrid columns={isSeminar ? 4 : 3} gap={isSeminar ? 4 : 6}>
        {otherLayoutLinks.map((l) => {
          const imgSrc = isSeminar
            ? seminarNavLinkImgSrcMapping[l.key]
            : !isSeminar
            ? consultingNavLinkImgSrcMapping[l.key]
            : null

          return (
            <LinkBox w={`${imgWidth}px`} key={l.key} mb={5}>
              {imgSrc ? (
                <NextImage
                  src={imgSrc}
                  width={imgWidth}
                  height={imgHeight}
                  alt=""
                />
              ) : (
                <Box
                  w={`${imgWidth}px`}
                  h={`${imgHeight}px`}
                  bgColor="moneiro-color-main-orange"
                  color="#fff"
                  px={3}
                  py={6}
                  borderRadius="5px"
                >
                  {l.title}
                </Box>
              )}
              <NextLink href={l.href} passHref prefetch={false} legacyBehavior>
                <LinkOverlay
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mt={2}
                >
                  <chakra.div textStyle="12-bold">{l.title}</chakra.div>
                  <ChevronRightIcon boxSize="18px" />
                </LinkOverlay>
              </NextLink>
            </LinkBox>
          )
        })}
      </SimpleGrid>
    </Stack>
  )
}

const seminarNavLinkImgSrcMapping: Record<SeminarNavLinkImgSrcMappingKey, any> =
  {
    "nisa-revise-seminar": imgNisaReviseSeminar,
    "nisa-basic2-seminar": imgNisaBasic2Seminar,
    "nisa-basic3-seminar": imgNisaBasic3Seminar,
    "nisa-levelup-seminar": imgNisaLevelupSeminar,
    "nisa-growth-seminar": imgNisaGrowthSeminar,
    "ideco-seminar": imgIdecoSeminar,
    "ideco2-seminar": imgIdeco2Seminar,
    "smart-seminar": imgSmartSeminar,
    "3-funds-seminar": img3FundsSeminar,
    "mortgage-seminar": imgMortgageSeminar,
    "solution-50s": imgSolution50sSeminar,
  }

const consultingNavLinkImgSrcMapping: Record<
  ConsultingNavLinkImgSrcMappingKey,
  any
> = {
  "future-consulting": imgFutureConsulting,
  "edu-consulting": imgEduConsulting,
  "nisa-consulting": imgNisaConsulting,
  "ideco-consulting": imgIdecoConsulting,
  "mutual-funds-consulting": imgMutualFundsConsulting,
  "insurance-consulting": imgInsuranceConsulting,
}

type SeminarNavLinkImgSrcMappingKey =
  | typeof NISA_REVISE_SEMINAR_LAYOUT_LINK["key"]
  | typeof NISA_BASIC2_SEMINAR_LAYOUT_LINK["key"]
  | typeof NISA_BASIC3_SEMINAR_LAYOUT_LINK["key"]
  | typeof NISA_LEVELUP_SEMINAR_LAYOUT_LINK["key"]
  | typeof NISA_GROWTH_SEMINAR_LAYOUT_LINK["key"]
  | typeof IDECO_SEMINAR_LAYOUT_LINK["key"]
  | typeof IDECO2_SEMINAR_LAYOUT_LINK["key"]
  | typeof SMART_SEMINAR_LAYOUT_LINK["key"]
  | typeof THREE_FUNDS_SEMINAR_LAYOUT_LINK["key"]
  | typeof MORTGAGE_SEMINAR_LAYOUT_LINK["key"]
  | typeof SOLUTION_50S_LAYOUT_LINK["key"]

type ConsultingNavLinkImgSrcMappingKey =
  | typeof FUTURE_CONSULTING_LAYOUT_LINK["key"]
  | typeof EDU_CONSULTING_LAYOUT_LINK["key"]
  | typeof NISA_CONSULTING_LAYOUT_LINK["key"]
  | typeof IDECO_CONSULTING_LAYOUT_LINK["key"]
  | typeof MUTUAL_FUNDS_CONSULTING_LAYOUT_LINK["key"]
  | typeof INSURANCE_CONSULTING_LAYOUT_LINK["key"]

const useHeaderMdMenu = () => {
  const [inTransition, setInTransition] = useState(false)
  const router = useRouter()

  // ページ遷移に応じてメガメニューの表示/非表示を制御する
  useEffect(() => {
    const handleRouterChangeStart = () => {
      setInTransition(true)
    }
    const handleRouterChangeComplete = () => {
      setInTransition(false)
    }
    // ページ遷移時にはメガメニューを表示不可とする
    router.events.on("routeChangeStart", handleRouterChangeStart)
    router.events.on("routeChangeComplete", handleRouterChangeComplete)
    router.events.on("routeChangeError", handleRouterChangeComplete)

    return () => {
      router.events.off("routeChangeStart", handleRouterChangeStart)
      router.events.off("routeChangeComplete", handleRouterChangeComplete)
      router.events.off("routeChangeError", handleRouterChangeComplete)
    }
  }, [router])

  return {
    // メガメニューを表示できるか
    isOpenable: inTransition === false,
  }
}
