import { useTheme } from "@chakra-ui/react"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import React, { useState } from "react"
import { HeaderContentProps } from "./Header"

/** 常に表示されるレイアウト */
export const StickyHeaderLayout = ({
  isOpen,
  ...props
}: {
  children: React.ReactElement<HeaderContentProps>
  isOpen?: boolean
}) => {
  const theme = useTheme()
  return React.cloneElement(props.children, {
    position: "sticky",
    top: 0,
    left: 0,
    right: 0,
    zIndex: isOpen ? theme.zIndices.popover : "sticky",
  })
}

/** スクロールによって表示/非表示が変わるレイアウト */
export const ScrollToVisibleHeaderLayout = ({
  isOpen,
  ...props
}: {
  children: React.ReactElement<HeaderContentProps>
  isOpen?: boolean
}) => {
  const [visible, setVisible] = useState(true)

  // 下にスクロールしたらメニューを非表示, 上にスクロールした場合は表示
  useScrollPosition(({ prevPos, currPos }) => {
    setVisible(currPos.y > prevPos.y)
  })

  // スクロールしてしばらくしたら表示
  useScrollPosition(
    () => {
      setVisible(true)
    },
    undefined,
    undefined,
    undefined,
    20_000
  )

  return React.cloneElement(props.children, {
    position: "sticky",
    top: 0,
    left: 0,
    right: 0,
    // zIndex: isOpen ? theme.zIndices.popover : "sticky",
    zIndex: "sticky",
    visibility: visible ? "visible" : "hidden",
    transition: `all 200ms ${visible ? "ease-in" : "ease-out"}`,
    transform: visible ? "none" : "translate(0, -100%)",
  })
}
