import { StaticRoutes } from "../../lib/constants"

export type LayoutLink = {
  key: string
  href: string
  title: string
  // 外部リンクかどうか
  external?: boolean
}

// リンク単体
export const TOP_LAYOUT_LINK = {
  key: "top",
  href: "/",
  title: "トップページ",
} as const

export const SIMULATION_LAYOUT_LINK = {
  key: "simulation",
  href: "/simulation/3min",
  title: "診断する",
} as const

export const SEMINAR_LAYOUT_LINK = {
  key: "money-seminar",
  href: "/money-seminar",
  title: "セミナーで学ぶ",
} as const

export const ONLINE_SEMINAR_LAYOUT_LINK = {
  ...SEMINAR_LAYOUT_LINK,
  key: "online-seminar",
  title: "オンラインセミナーTOP",
} as const

export const NISA_REVISE_SEMINAR_LAYOUT_LINK = {
  key: "nisa-revise-seminar",
  href: "/money-seminar/nisa-revise",
  title: "新NISAの活用方法と落とし穴",
} as const

export const NISA_BASIC2_SEMINAR_LAYOUT_LINK = {
  key: "nisa-basic2-seminar",
  href: "/money-seminar/nisa-basic2",
  title: "つみたてNISAの基礎とお金の基本戦略",
} as const

export const NISA_BASIC3_SEMINAR_LAYOUT_LINK = {
  key: "nisa-basic3-seminar",
  href: "/money-seminar/nisa-basic3",
  title: "新NISAで始める資産運用 -基本編-",
} as const

export const NISA_LEVELUP_SEMINAR_LAYOUT_LINK = {
  key: "nisa-levelup-seminar",
  href: "/money-seminar/nisa-levelup",
  title: "つみたてNISAレベルアップセミナー",
} as const

export const NISA_GROWTH_SEMINAR_LAYOUT_LINK = {
  key: "nisa-growth-seminar",
  href: "/money-seminar/nisa-growth",
  title: "NISA成長投資枠の失敗しない銘柄選び",
} as const

export const IDECO_SEMINAR_LAYOUT_LINK = {
  key: "ideco-seminar",
  href: "/money-seminar/ideco",
  title: "iDeCoで始める資産運用",
} as const

export const IDECO2_SEMINAR_LAYOUT_LINK = {
  key: "ideco2-seminar",
  href: "/money-seminar/ideco2",
  title: "プロも実践するiDeCoの活用法",
} as const

export const SMART_SEMINAR_LAYOUT_LINK = {
  key: "smart-seminar",
  href: "/money-seminar/smart",
  title: "賢いお金の増やし方入門",
} as const

export const THREE_FUNDS_SEMINAR_LAYOUT_LINK = {
  key: "3-funds-seminar",
  href: "/money-seminar/3-funds",
  title: "教育・住宅・将来資金 賢い準備と増やし方",
} as const

export const MORTGAGE_SEMINAR_LAYOUT_LINK = {
  key: "mortgage-seminar",
  href: "/money-seminar/mortgage",
  title: "住宅ローンの賢い借り方、返し方",
} as const

export const CONSULTING_LAYOUT_LINK = {
  key: "consulting",
  href: "/consulting",
  title: "相談する",
} as const

export const ONLINE_CONSULTING_LAYOUT_LINK = {
  ...CONSULTING_LAYOUT_LINK,
  key: "online-consulting",
  title: "オンライン相談TOP",
} as const

export const FUTURE_CONSULTING_LAYOUT_LINK = {
  key: "future-consulting",
  href: "/consulting/future",
  title: "将来資金のご相談",
} as const

export const EDU_CONSULTING_LAYOUT_LINK = {
  key: "edu-consulting",
  href: "/consulting/edu",
  title: "教育資金のご相談",
} as const

export const NISA_CONSULTING_LAYOUT_LINK = {
  key: "nisa-consulting",
  href: "/consulting/nisa",
  title: "NISAのご相談",
} as const

export const IDECO_CONSULTING_LAYOUT_LINK = {
  key: "ideco-consulting",
  href: "/consulting/ideco",
  title: "iDeCoのご相談",
} as const

export const MUTUAL_FUNDS_CONSULTING_LAYOUT_LINK = {
  key: "mutual-funds-consulting",
  href: "/consulting/mutual-funds",
  title: "投資信託のご相談",
} as const

export const INSURANCE_CONSULTING_LAYOUT_LINK = {
  key: "insurance-consulting",
  href: "/consulting/insurance",
  title: "生命保険のご相談",
} as const

export const MYPAGE_LAYOUT_LINK = {
  key: "mypage",
  href: "/mypage",
  title: "マイページ",
} as const

export const FAQ_LAYOUT_LINK = {
  key: "faq",
  href: "/faq",
  title: "よくある質問",
} as const

export const REVIEWS_LAYOUT_LINK = {
  key: "reviews",
  href: "/reviews",
  title: "体験レビュー・口コミ",
} as const

export const MEDIA_LAYOUT_LINK = {
  key: "media",
  href: "/media",
  title: "マネイロメディア",
} as const

export const FOR_BUSINESS_LAYOUT_LINK = {
  key: "for-business",
  href: "https://hs-lp.moneiro.jp/feature/for-business",
  title: "法人向けサービス",
  external: true,
} as const

export const COMPANY_LAYOUT_LINK = {
  key: "company",
  href: StaticRoutes.Root,
  title: "運営会社",
  external: true,
} as const

export const CONTACT_LAYOUT_LINK = {
  key: "contact",
  href: "/contact",
  title: "お問い合わせ",
} as const

export const INSTAGRAM_LAYOUT_LINK = {
  key: "instagram",
  href: StaticRoutes.social.Instagram,
  title: "マネイロ公式 Instagramアカウント",
} as const

export const LINE_LAYOUT_LINK = {
  key: "line",
  href: StaticRoutes.social.Line,
  title: "マネイロ公式 LINEアカウント",
} as const

export const RULE_LAYOUT_LINK = {
  key: "rule",
  href: "/rule",
  title: "利用規約",
} as const

export const PRIVACY_LAYOUT_LINK = {
  key: "privacy",
  href: "/privacy",
  title: "個人情報保護方針",
} as const

export const SOLICITATION_LAYOUT_LINK = {
  key: "solicitation",
  href: "/solicitation",
  title: "勧誘方針",
} as const

export const FINANCIAL_LAYOUT_LINK = {
  key: "financial",
  href: "/financial",
  title: "金融商品取引法に基づく表示",
} as const

export const SOLUTION_50S_LAYOUT_LINK = {
  key: "solution-50s",
  href: "/money-seminar/solution-50s",
  title: "50代から始める資産運用",
} as const

// リンク集
// 基本的には使用側でリンク単体をimportしてリンク集をそれぞれ作成してもらう想定で、
// よく使うものだけここに定義する
export const SEMINAR_LAYOUT_LINKS: LayoutLink[] = [
  ONLINE_SEMINAR_LAYOUT_LINK,
  NISA_BASIC3_SEMINAR_LAYOUT_LINK,
  NISA_GROWTH_SEMINAR_LAYOUT_LINK,
  IDECO2_SEMINAR_LAYOUT_LINK,
  SMART_SEMINAR_LAYOUT_LINK,
  THREE_FUNDS_SEMINAR_LAYOUT_LINK,
  MORTGAGE_SEMINAR_LAYOUT_LINK,
  SOLUTION_50S_LAYOUT_LINK,
]

export const CONSULTING_LAYOUT_LINKS: LayoutLink[] = [
  ONLINE_CONSULTING_LAYOUT_LINK,
  FUTURE_CONSULTING_LAYOUT_LINK,
  EDU_CONSULTING_LAYOUT_LINK,
  NISA_CONSULTING_LAYOUT_LINK,
  IDECO_CONSULTING_LAYOUT_LINK,
  MUTUAL_FUNDS_CONSULTING_LAYOUT_LINK,
  INSURANCE_CONSULTING_LAYOUT_LINK,
]

export const OTHER_LINKS: LayoutLink[] = [COMPANY_LAYOUT_LINK]

export const CONTACT_LINKS: LayoutLink[] = [CONTACT_LAYOUT_LINK]

export const POLICY_LINKS: LayoutLink[] = [
  RULE_LAYOUT_LINK,
  PRIVACY_LAYOUT_LINK,
  SOLICITATION_LAYOUT_LINK,
  FINANCIAL_LAYOUT_LINK,
]
