import {
  ComponentWithAs,
  forwardRef,
  Link,
  LinkProps as ChakraLinkProps,
} from "@chakra-ui/react"
import { split } from "@chakra-ui/utils"
import NextLink, { LinkProps as NextLinkProps } from "next/link"
import { nextLinkPropsNames } from "./NextLink"

export type NavLinkProps = Omit<ChakraLinkProps, "href"> & NextLinkProps

export const NavLink: ComponentWithAs<"a", NavLinkProps> = forwardRef(
  (props, ref) => {
    const [nextLinkProps, other] = split(props, nextLinkPropsNames)
    return (
      <NextLink {...nextLinkProps} passHref legacyBehavior>
        <Link ref={ref} {...other} />
      </NextLink>
    )
  }
)
