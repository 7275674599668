import { SearchIcon } from "@chakra-ui/icons"
import { Box, chakra, HTMLChakraProps, IconButton } from "@chakra-ui/react"
import { useRouter } from "next/router"
import {
  FC,
  KeyboardEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
} from "react"

export type MediaSearchInputProps = {
  /** マウント時にフォーカスするかどうか */
  focusOnMount?: boolean
} & HTMLChakraProps<"input">

export const MediaSearchInput: FC<MediaSearchInputProps> = ({
  focusOnMount = false,
  ...props
}) => {
  const { inputRef, onKeyDown, onClick } = useMediaSeachInput(focusOnMount)

  return (
    <Box
      role="search"
      aria-label="マネイロメディアの記事"
      display="inline-block"
      w={props.w ?? props.width} // アイコンを絶対配置しているため、inputの幅と合わせる必要がある
      pos="relative"
    >
      <chakra.input
        ref={inputRef}
        onKeyDown={onKeyDown}
        placeholder="記事を探す"
        role="searchbox"
        aria-label="マネイロメディアの記事"
        bgColor="#f8f8f8"
        textStyle="12-reg"
        p={2}
        borderWidth="1px"
        borderColor="moneiro-color-fill-gray-sub"
        borderRadius="base"
        _placeholder={{ color: "#9e9e9e" }}
        {...props}
      />
      <IconButton
        onClick={(e) => onClick(e, inputRef.current?.value ?? "")}
        aria-label="マネイロメディアの記事を検索する"
        variant="unstyled"
        pos="absolute"
        bottom="0px"
        right="0px"
        icon={<SearchIcon boxSize="16px" color="#9e9e9e" />}
      />
    </Box>
  )
}

const useMediaSeachInput = (focusOnMount: boolean) => {
  const inputRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (focusOnMount) {
      inputRef.current?.focus()
    }
  }, [focusOnMount])

  const router = useRouter()
  const search = useCallback(
    (q: string) => {
      // 入力値が空文字の場合は何もしない
      if (q === "") {
        return
      }
      const path = "/media/search?"
      const urlSearchParams = new URLSearchParams({ q })
      void router.push(`${path}${urlSearchParams}`)
    },
    [router]
  )
  const onKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      // エンターキー以外が押下された場合とエンターキーが変換確定のために押下された場合は何もしない
      if (e.key !== "Enter" || e.nativeEvent.isComposing) {
        return
      }
      e.preventDefault()
      search(e.currentTarget.value)
    },
    [search]
  )
  const onClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>, q: string) => {
      e.preventDefault()
      search(q)
    },
    [search]
  )

  return { inputRef, onKeyDown, onClick }
}
