import { Box, BoxProps } from "@chakra-ui/react"
import React, { PropsWithChildren } from "react"

export type VisibleProps = PropsWithChildren<
  | {
      baseOnly: true
      mdOnly?: false
    }
  | {
      baseOnly?: false
      mdOnly: true
    }
> &
  BoxProps

/** SP or PCどちらかのみで表示されるコンポーネント */
export const Visible = ({
  baseOnly,
  mdOnly,
  children,
  ...props
}: VisibleProps) => {
  return (
    <Box
      display={
        baseOnly ? { base: "block", md: "none" } : { base: "none", md: "block" }
      }
      {...props}
    >
      {children}
    </Box>
  )
}
