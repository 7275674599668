/** NextLinkでnext/linkをインポートしやすくするためだけのファイル */
import type { LinkProps } from "next/link"

export const nextLinkPropsNames = [
  "href",
  "as",
  "replace",
  "scroll",
  "shallow",
  "passHref",
  "prefetch",
  "locale",
] as (keyof LinkProps)[]
