import { AddIcon, ChevronRightIcon, MinusIcon } from "@chakra-ui/icons"
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  BoxProps,
  Center,
  HStack,
  Link,
  ModalBody,
  SimpleGrid,
  Stack,
  VStack,
  chakra,
  ModalBodyProps,
} from "@chakra-ui/react"
import { COMPANY } from "@onemile/corporate-data"
import imgBanner from "@~/assets/layout/header/base/banner.png"
import imgConsultingIcon from "@~/assets/layout/header/base/consulting-icon.png"
import imgInstagramIcon from "@~/assets/layout/header/base/instagram-icon.png"
import imgLINEIcon from "@~/assets/layout/header/base/line-icon.png"
import imgMypageIcon from "@~/assets/layout/header/base/mypage-icon.png"
import imgSeminarIcon from "@~/assets/layout/header/base/seminar-icon.png"
import imgSimulationIcon from "@~/assets/layout/header/base/simulation-icon.png"
import { isIOS } from "mobile-device-detect"
import { NextImage } from "../../system"
import { NavLink } from "../../system/NavLink"
import { INSTAGRAM_LAYOUT_LINK, LINE_LAYOUT_LINK, LayoutLink } from "../links"
import { useHeader } from "./Header"

export type HeaderMenuModalBodyProps = {
  links: ReturnType<typeof useHeader>
  isAffiliate: boolean
} & ModalBodyProps

export const HeaderMenuModalBody = ({
  links,
  isAffiliate,
  ...modalProps
}: HeaderMenuModalBodyProps) => {
  return (
    <ModalBody as="nav" p="0" {...modalProps}>
      <Box bgColor="#fff" textStyle="14-bold" py={2}>
        <Stack spacing={0}>
          {!isAffiliate && (
            <NavLink
              href={links.simulation.href}
              prefetch={false}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              px={6}
              py={2}
            >
              <HStack spacing={1}>
                <NextImage
                  src={imgSimulationIcon}
                  width={40}
                  height={40}
                  alt=""
                />
                <chakra.div>{links.simulation.title}</chakra.div>
              </HStack>
              <ChevronRightIcon boxSize="24px" />
            </NavLink>
          )}
          <Accordion allowMultiple w="full">
            <HeaderAccordionItem
              kind="seminar"
              title="セミナーで学ぶ"
              links={links.seminar}
            />
            <HeaderAccordionItem
              kind="consulting"
              title="相談する"
              links={links.consulting}
            />
          </Accordion>
          <NavLink
            href={links.mypage.href}
            prefetch={false}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            px={6}
            py={2}
          >
            <HStack spacing={1}>
              <NextImage src={imgMypageIcon} width={40} height={40} alt="" />
              <chakra.div>{links.mypage.title}</chakra.div>
            </HStack>
            <ChevronRightIcon boxSize="24px" />
          </NavLink>
        </Stack>
        <Divider />
        <Stack spacing={2} px={6} py={4}>
          {links.sub.map((l) => {
            return (
              <NavLink
                href={l.href}
                prefetch={false}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                key={l.key}
              >
                <chakra.div>{l.title}</chakra.div>
                <ChevronRightIcon boxSize="24px" />
              </NavLink>
            )
          })}
        </Stack>
        <Divider />
        <VStack spacing={4} pt={4} pb={2}>
          {!isAffiliate && (
            <NavLink href={links.simulation.href} prefetch={false}>
              <NextImage
                src={imgBanner}
                width={321}
                height={177}
                alt="あなたの20年後の資産をシミュレーション 3分で無料診断してみる"
              />
            </NavLink>
          )}
          <SNSLinks />
        </VStack>
      </Box>
      <Stack
        spacing={2}
        bgColor="moneiro-color-fill-black"
        textStyle="14-bold"
        color="#fff"
        px={6}
        pt={6}
        // iOSの場合は下部のアドレスバー分を考慮する
        pb={isIOS ? 20 : 4}
      >
        <SimpleGrid columns={2} gap={6}>
          {links.other.map((l) => {
            return (
              <Link
                href={l.href}
                isExternal
                display="flex"
                alignItems="center"
                _hover={{
                  textDecoration: "none",
                }}
                key={l.key}
              >
                <chakra.div borderBottomWidth="1px" borderColor="#fff">
                  {l.title}
                </chakra.div>
                <OtherIcon />
              </Link>
            )
          })}
          {links.contact.map((l) => {
            return (
              <NavLink
                href={l.href}
                prefetch={false}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                key={l.key}
              >
                <chakra.div>{l.title}</chakra.div>
              </NavLink>
            )
          })}
          {COMPANY.offices.stores.map((store) => (
            <chakra.div textStyle="12-bold" key={store.key}>
              {store.tel && (
                <>
                  {store.short}：
                  <chakra.div textStyle="18-bold">{store.tel}</chakra.div>
                </>
              )}
            </chakra.div>
          ))}
        </SimpleGrid>
        <chakra.div fontSize="12px">10：00〜19：00（平日）</chakra.div>
      </Stack>
    </ModalBody>
  )
}

const HeaderAccordionItem = ({
  kind,
  title,
  links,
}: {
  kind: "seminar" | "consulting"
  title: string
  links: LayoutLink[]
}) => {
  const imgSrc =
    kind === "seminar"
      ? imgSeminarIcon
      : kind === "consulting"
      ? imgConsultingIcon
      : null

  return (
    <AccordionItem border="none">
      {({ isExpanded }) => {
        return (
          <>
            <AccordionButton justifyContent="space-between" px={6} py={2}>
              <HStack spacing={1}>
                {imgSrc && (
                  <NextImage src={imgSrc} width={40} height={40} alt="" />
                )}
                <chakra.div textStyle="14-bold">{title}</chakra.div>
              </HStack>
              <Center boxSize="24px">
                {isExpanded ? (
                  <MinusIcon boxSize="12px" />
                ) : (
                  <AddIcon boxSize="12px" />
                )}
              </Center>
            </AccordionButton>
            <AccordionPanel
              bgColor="moneiro-color-fill-gray-basic"
              pl="75px"
              pr={6}
              py={2}
            >
              <Stack spacing={2}>
                {links.map((l) => {
                  return (
                    <NavLink
                      href={l.href}
                      prefetch={false}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      key={l.key}
                    >
                      <chakra.div>{l.title}</chakra.div>
                      <ChevronRightIcon boxSize="24px" />
                    </NavLink>
                  )
                })}
              </Stack>
            </AccordionPanel>
          </>
        )
      }}
    </AccordionItem>
  )
}

const Divider = () => {
  return (
    <Center px={3}>
      <Box w="full" borderTopWidth="1px" borderTopColor="#ddd" />
    </Center>
  )
}

const SNSLinks = () => {
  return (
    <HStack spacing={6}>
      <chakra.div
        fontWeight="bold"
        fontSize="27px"
        lineHeight="42px"
        letterSpacing="0.05em"
      >
        Follow Me!
      </chakra.div>
      <HStack spacing={2}>
        <NavLink
          href={INSTAGRAM_LAYOUT_LINK.href}
          prefetch={false}
          display="flex"
          justifyContent="center"
        >
          <NextImage
            src={imgInstagramIcon}
            width={36}
            height={36}
            alt={INSTAGRAM_LAYOUT_LINK.title}
          />
        </NavLink>
        <NavLink
          href={LINE_LAYOUT_LINK.href}
          prefetch={false}
          display="flex"
          justifyContent="center"
        >
          <NextImage
            src={imgLINEIcon}
            width={36}
            height={36}
            alt={LINE_LAYOUT_LINK.title}
          />
        </NavLink>
      </HStack>
    </HStack>
  )
}

const OtherIcon = () => {
  const baseStyle: BoxProps = {
    w: "16px",
    h: "11px",
    bgColor: "#fff",
  }

  return (
    <Box ml={3} pos="relative">
      <Box {...baseStyle} />
      <Box
        {...baseStyle}
        borderTopWidth="1.5px"
        borderRightWidth="1.5px"
        borderColor="moneiro-color-fill-black"
        pos="absolute"
        top="2px"
        left="-2px"
      />
    </Box>
  )
}
