import { SearchIcon } from "@chakra-ui/icons"
import {
  As,
  Box,
  Center,
  chakra,
  Flex,
  HStack,
  HTMLChakraProps,
  IconButton,
  Modal,
  ModalContent,
  useDisclosure,
  VisuallyHidden,
} from "@chakra-ui/react"
import { usePCLayout } from "components/system"
import { useRouter } from "next/router"
import { ReactNode, useCallback, useEffect } from "react"
import { HamburgerCloseIcon, HamburgerIcon } from "../../icon"
import { Logo } from "../../logo"
import { NavLink } from "../../system/NavLink"
import {
  CONSULTING_LAYOUT_LINKS,
  CONTACT_LINKS,
  FAQ_LAYOUT_LINK,
  LayoutLink,
  MEDIA_LAYOUT_LINK,
  MYPAGE_LAYOUT_LINK,
  OTHER_LINKS,
  REVIEWS_LAYOUT_LINK,
  SEMINAR_LAYOUT_LINKS,
  SIMULATION_LAYOUT_LINK,
} from "../links"
import { StickyHeaderLayout } from "./HeaderLayout"
import { HeaderMdMenu } from "./HeaderMdMenu"
import { HeaderMenuModalBody } from "./HeaderMenuModalBody"
import { useHeaderHeight } from "./hooks"
import { MediaSearchInput } from "./MediaSearchInput"

export type HeaderProps = {
  headerAs?: As
  rightItem?: ReactNode
  beforeHeader?: ReactNode
  afterHeader?: ReactNode
  isAffiliate?: boolean
  /** マネイロメディアの記事を検索するための入力を表示するか */
  displayMediaSearchInput?: boolean
} & HTMLChakraProps<"header">

/** ヘッダー
 *
 * `StickyHeaderLayout`を使用します
 */
export const Header = (props: HeaderProps) => {
  const menu = useDisclosure()
  const mediaSearchInput = useDisclosure()

  return (
    <StickyHeaderLayout isOpen={menu.isOpen}>
      <HeaderContent
        menu={menu}
        mediaSearchInput={mediaSearchInput}
        {...props}
      />
    </StickyHeaderLayout>
  )
}

export type HeaderContentProps = HeaderProps & {
  menu?: ReturnType<typeof useDisclosure>
  mediaSearchInput?: ReturnType<typeof useDisclosure>
}

export const HeaderContent = ({
  headerAs,
  rightItem,
  beforeHeader,
  afterHeader,
  isAffiliate = false,
  displayMediaSearchInput = false,
  menu: menuFromProps,
  mediaSearchInput: mediaSearchInputFromProps,
  ...props
}: HeaderContentProps) => {
  const links = useHeader()
  const menu = useDisclosure(menuFromProps)
  const mediaSearchInput = useDisclosure(mediaSearchInputFromProps)

  const isMD = usePCLayout()
  const [_, setHeaderHeight] = useHeaderHeight()
  useEffect(() => {
    setHeaderHeight(getHeight(isMD))
  }, [isMD, setHeaderHeight])

  // getLayoutパターンを使用しているページ間で遷移した場合、
  // Headerが再レンダリングされず、開いているものが閉じないのでその対応
  // ただし、SPのみmenu.isOpenの値が切り替わるようになっているため、実質効果が出るのはSPのみ
  const router = useRouter()
  const closeOpenings = useCallback(() => {
    menu.onClose()
    mediaSearchInput.onClose()
  }, [menu, mediaSearchInput])
  useEffect(() => {
    router.events.on("routeChangeStart", closeOpenings)
    return () => {
      router.events.off("routeChangeStart", closeOpenings)
    }
  }, [router.events, closeOpenings])

  return (
    <chakra.header
      // メガメニューがヘッダーの直下に表示されるようにする
      pos={{ base: "unset", md: "relative" }}
      {...props}
    >
      {beforeHeader}
      <HStack
        justify="space-between"
        h={{ base: BaseHeaderHeight, md: PCHeaderHeight }}
        bgColor="#fff"
        px={{ base: 3, md: 6 }}
        borderBottomWidth="1px"
        borderBottomColor={
          mediaSearchInput.isOpen
            ? "moneiro-color-white"
            : "moneiro-color-line-gray-basic"
        }
        // ハンバーガーメニューの上に表示されるようにする
        pos={{ base: "relative", md: "unset" }}
      >
        <NavLink href="/" title="マネイロ" prefetch={false}>
          <Center as={headerAs || "h1"}>
            <Logo
              title="マネイロ"
              w={{ base: "112px", md: "116px" }}
              h={{ base: "32px", md: "33px" }}
            />
          </Center>
        </NavLink>
        <VisuallyHidden>
          <h1>サイトナビゲーション</h1>
        </VisuallyHidden>
        {isMD ? (
          <Flex as="nav" h="full" textStyle="14-reg" fontWeight={500}>
            <HeaderMdMenu
              links={links}
              isAffiliate={isAffiliate}
              displayMediaSearchInput={displayMediaSearchInput}
            />
            {rightItem}
          </Flex>
        ) : (
          <HStack as="nav" spacing={3}>
            {rightItem}
            {displayMediaSearchInput && (
              <IconButton
                size="sm"
                variant="ghost"
                aria-label={
                  mediaSearchInput.isOpen
                    ? "マネイロメディアの記事を検索するための入力を閉じる"
                    : "マネイロメディアの記事を検索するための入力を開く"
                }
                icon={
                  mediaSearchInput.isOpen ? (
                    <HamburgerCloseIcon boxSize="24px" />
                  ) : (
                    <SearchIcon boxSize="16px" />
                  )
                }
                {...mediaSearchInput.getButtonProps()}
              />
            )}
            <IconButton
              size="sm"
              variant="ghost"
              aria-label={menu.isOpen ? "メニューを閉じる" : "メニューを開く"}
              icon={
                menu.isOpen ? (
                  <HamburgerCloseIcon boxSize="24px" />
                ) : (
                  <HamburgerIcon boxSize="24px" />
                )
              }
              {...menu.getButtonProps()}
            />
          </HStack>
        )}
      </HStack>
      {afterHeader}
      {isMD === false && displayMediaSearchInput && mediaSearchInput.isOpen && (
        <Box
          bgColor="moneiro-color-white"
          p={3}
          pt={0}
          borderBottomWidth="1px"
          borderBottomColor="moneiro-color-line-gray-basic"
        >
          <MediaSearchInput focusOnMount w="full" />
        </Box>
      )}
      {isMD === false && (
        <Modal
          size="full"
          isOpen={menu.isOpen}
          onClose={menu.onClose}
          scrollBehavior="inside"
          motionPreset="none"
        >
          <ModalContent mt={BaseHeaderHeight}>
            <HeaderMenuModalBody
              links={links}
              isAffiliate={isAffiliate}
              maxH={`calc(100dvh - ${BaseHeaderHeight})`}
            />
          </ModalContent>
        </Modal>
      )}
    </chakra.header>
  )
}

export const useHeader = () => ({
  simulation: SIMULATION_LAYOUT_LINK,
  seminar: SEMINAR_LAYOUT_LINKS,
  consulting: CONSULTING_LAYOUT_LINKS,
  mypage: MYPAGE_LAYOUT_LINK,
  sub: SUB_LAYOUT_LINKS,
  other: OTHER_LINKS,
  contact: CONTACT_LINKS,
})

const SUB_LAYOUT_LINKS: LayoutLink[] = [
  FAQ_LAYOUT_LINK,
  REVIEWS_LAYOUT_LINK,
  MEDIA_LAYOUT_LINK,
]

const getHeight = (isMD: boolean) => {
  switch (isMD) {
    case false:
      return { height: 70 }
    case true:
    default:
      return { height: 78 }
  }
}

const BaseHeaderHeight = "70px"
const PCHeaderHeight = "78px"
export const HeaderHeight = "79px"
